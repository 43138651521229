<div fxLayout="row wrap" fxLayoutAlign="center stretch">
  <mat-card fxFlex="0 0 400px" fxLayout="column">
    <mat-card-title>Testdurchführung überwachen</mat-card-title>
    <mat-card-content>
      <div fxLayoutGap="10px" fxLayout="column">
        <p *ngIf="accessObjects.length === 0">
          Sie sind angemeldet. Aktuell sind keine Bereiche zur Überwachung für Sie freigegeben.
        </p>
        <button mat-raised-button color="primary" (click)="buttonGotoMonitor(accessObject)"
                *ngFor="let accessObject of accessObjects">
          {{accessObject.name}}
        </button>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="foreground" (click)="resetLogin()">Neu anmelden</button>
    </mat-card-actions>
  </mat-card>

  <mat-card fxFlex="0 0 400px" class="mat-card-gray">
    <mat-card-title>{{ 'app_title' | customtext:'app_title':cts.updateCount }}</mat-card-title>

    <mat-card-content>

      <status-card></status-card>

    </mat-card-content>
  </mat-card>
</div>
