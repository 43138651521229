<div fxLayout="column">
  <div *ngIf="loginName">
    <p><b>Status: Angemeldet als "{{loginName}}"</b></p>
    <p style="margin-bottom: 0px;">
      <b *ngIf="loginAuthority.length > 1">Berechtigungen:</b>
      <b *ngIf="loginAuthority.length === 1">Berechtigung:</b>
    </p>
    <ul style="margin: 0px;">
      <li *ngFor="let loginAuth of loginAuthority">{{loginAuth}}</li>
    </ul>
  </div>
  <p *ngIf="!loginName"><b>Status: Derzeit nicht angemeldet.</b></p>
  <p style="margin-bottom: 0px;"><b>Angaben zu dieser Web-Anwendung:</b></p>
  <ul style="margin: 0px;">
    <li>Interner Programmname: {{ appName }}</li>
    <li>Programmversion: {{ appVersion }}</li>
    <li *ngIf="!isProductionMode">Build-Modus: Dev</li>
    <li>Erforderliche Version der Server-Programmierung: {{ apiVersionExpected }}</li>
    <li>Copyright: {{ appPublisher }}</li>
  </ul>
</div>
