<div fxLayout="row wrap" fxLayoutAlign="center stretch">
  <mat-card fxFlex="0 0 400px" fxLayout="column">
    <mat-card-title>{{ bookletSelectTitle }}</mat-card-title>
    <mat-card-content>
      <div fxLayoutGap="10px" fxLayout="column">
        <p style="color: chocolate"><b>{{ problemText }}</b></p>
        <button mat-raised-button color="primary" (click)="startTest(b)"
                [disabled]="b.locked" *ngFor="let b of booklets">
          <div class="booklet_title">{{b.label}}</div>
          <div class="booklet_status">{{b.locked ? 'gesperrt' : (b.running ? 'Fortsetzen' : 'Starten')}}</div>
        </button>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="foreground" (click)="resetLogin()">Neu anmelden</button>
    </mat-card-actions>
  </mat-card>

  <mat-card fxFlex="0 0 400px" class="mat-card-gray">
    <mat-card-title>{{ 'app_title' | customtext:'app_title':cts.updateCount }}</mat-card-title>

    <mat-card-content>
      <p *ngIf="booklets.length === 0">{{ 'login_bookletSelectPromptNull' | customtext: 'login_bookletSelectPromptNull':cts.updateCount}}</p>
      <p *ngIf="booklets.length === 1">{{ 'login_bookletSelectPromptOne' | customtext: 'login_bookletSelectPromptOne':cts.updateCount}}</p>
      <p *ngIf="booklets.length > 1">{{ 'login_bookletSelectPromptMany' | customtext: 'login_bookletSelectPromptMany':cts.updateCount}}</p>

      <status-card></status-card>

    </mat-card-content>
  </mat-card>
</div>
