<div class="logo">
  <a [routerLink]="['/']">
    <img src="assets/IQB-LogoA.png" matTooltip="Zur Startseite" alt="IQB-logo"/>
  </a>
</div>
<div class="error-msg" *ngIf="showError" [matTooltip]="errorData?.description" fxLayout="row" fxLayoutAlign="space-between center">
  <mat-icon>error</mat-icon>
  {{ errorData?.label }}
  <button mat-button (click)="closeErrorBox()" matTooltip="Fehlernachricht ausblenden" fxFlex="none">
    <mat-icon>clear</mat-icon>
  </button>
</div>
<div class="spinner" *ngIf="mds.isSpinnerOn$ | async">
  <mat-spinner></mat-spinner>
</div>
<router-outlet></router-outlet>
