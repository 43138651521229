<!-- this component is not sub route of app-root ! -->
<div class="root-body">
  <div fxLayout="row" fxLayoutAlign="center start">
    <mat-card fxFlex="0 0 500px">
      <mat-card-title>{{ 'app_title' | customtext:'app_title':cts.updateCount }} - Impressum/Datenschutz</mat-card-title>

      <!-- - - - - - - - - - - - - - - - - -->
      <mat-card-content>
        <p>Das <a href="http://www.iqb.hu-berlin.de" target="_blank">Institut zur Qualitätsentwicklung im Bildungswesen</a>
          {{ 'app_intro1' | customtext:'app_intro1':cts.updateCount }}</p>

        <p>Die mit diesem System erhobenen Daten enthalten grundsätzlich keinen direkten
          Personenbezug. Es werden z. B. nie Namen gespeichert. Um Auskünfte zu einer bestimmten Befragung bzw. Studie
          zu erhalten, wenden Sie sich bitte an das <a href="mailto:mechtel@iqb.hu-berlin.de">
            IQB</a>. Wir benötigen dazu den ungefähren Zeitraum und das Bundesland, in dem die Befragung bzw. Studie
          durchgeführt wurde.</p>

        <p>
          <em>Postanschrift:</em><br/>
          Humboldt-Universität zu Berlin<br/>
          Institut zur Qualitätsentwicklung im Bildungswesen<br/>
          Unter den Linden 6<br/>
          10099 Berlin</p>
        <p>
          <em>Sitz:</em><br/>
          Luisenstr. 56<br/>
          10117 Berlin<br/>
          Tel: +49 [30] 2093 - 46500 (Zentrale)<br/>
          Fax: +49 [30] 2093 - 46599<br/>
          E-Mail: <a href="mailto:iqboffice@iqb.hu-berlin.de">iqboffice@iqb.hu-berlin.de</a>
        </p>
        <p>
          <em>Name und Anschrift der Datenschutzbeauftragten</em><br/>
          Frau Gesine Hoffmann-Holland<br/>
          Tel: +49 (30) 2093-2591<br/>
          E-Mail: datenschutz@uv.hu-berlin.de<br/>
          <a href="http://www.hu-berlin.de/de/datenschutz" target="_blank">www.hu-berlin.de/de/datenschutz</a>
        </p>
        <status-card></status-card>
      </mat-card-content>
      <mat-card-actions>
        <button [routerLink]="['/']" mat-raised-button color="primary"><i class="material-icons">arrow_back</i> zurück zur Startseite</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
